

















































































// tslint:disable
import FacebookRegister from "@/components/social/FacebookRegister.vue";
import LinkedinRegister from "@/components/social/LinkedinRegister.vue";
import { FacebookUser, LinkedInUser } from '@/core/models/Entities';
import GestaoCandidato from "@/core/Services/Candidato/GestaoCandidato";
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import NavBar from "@/views/layouts/NavBar.vue";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import Cookies from 'js-cookie'

const namespace: string = "profile";

@Component({
  components: {
    "nav-bar": NavBar,
    "facebook-button": FacebookRegister,
    "linkedin-button": LinkedinRegister
  }
})
export default class LoginForm extends Vue {
  public username: string = "";
  public password: string = "";
  public myPopUp: Window | null = null;
  public typePassword: string = "password";
  public showPassword: boolean = false;
  public serverMessage?: string | null = null;
  public showDismissibleAlert: boolean = false;
  public loginPromise: Promise<any>|null = null;
  public alertVariant: string = "danger";
  private linkedinToken = '';
  public vagaNomeUrl: string = ""

  public gestaoCandidatoSRV: GestaoCandidato = new GestaoCandidato();

  @Action("userRequest", { namespace }) public userRequest: any;
  @Action("authRequest", { namespace }) public authRequest: any;
  @Getter("isAuthenticated", { namespace }) public isAuthenticated: any;
  @Getter("getProfile", { namespace }) public getProfile: any;
  @Getter("getProfileType", { namespace }) public getProfileType: any;

  @Prop({ default: false })
  private emitLogin!: boolean;

  @Prop({ default: '' })
  private nomeUrl!: string;
  
  @Prop({ default: '' })
  private companyUrl!: string;

  public async created() {
    sessionStorage.removeItem("redirectTo");
    this.whereToSend();
  }

  public mounted() {
    // se veio de uma vaga 
    if(this.$route.query.vagaURL) {
      this.vagaNomeUrl = this.$route.query.vagaURL+"";
    }
    // se esta na página da vaga 
    if(this.nomeUrl) {
      this.vagaNomeUrl = this.nomeUrl+"";
    }
  }

  public loggedInFacebookRegistered(accessToken: string) {
    this.registerUser(accessToken);
    this.whereToSend();
  }

  public loggedInFacebookNotRegistered(user: FacebookUser) {
    sessionStorage.setItem('facebook_user', JSON.stringify(user));
    this.$router.push({ name: 'ThirdPartyRegistry', query: { lang: this.$i18n.locale, vagaURL: ((this.vagaNomeUrl) ? this.vagaNomeUrl||"" : ""),
          organica: ((this.$route.query.organica) ? this.$route.query.organica||"" : "") } });
  }

  insertIntoCookies (
  key: string,
  value: unknown,
  encrypt?: boolean
) {
  let valueInString = JSON.stringify(value)

  if (encrypt) {
    valueInString = btoa(valueInString)
  }

  Cookies.set(key, valueInString)
}

  public whereToSend() {
    /* console.log('here') */
    let rurl: any = this.$route.query.redirectUrl;
    if (rurl) {
      sessionStorage.setItem("redirectTo", rurl);
    }
    if (sessionStorage.getItem("access_token")) {

      const loginData = {
          access_token: sessionStorage.getItem("access_token"),
          refresh_token: sessionStorage.getItem("refresh_token"),
          expires_in: new Date().getTime()+88600,
          token_type: 'Bearer',
          logged_at: new Date().getTime(),
        }

        this.insertIntoCookies(
          'logincandidateData',
          loginData,
        )

      if(this.companyUrl && this.vagaNomeUrl) {
        this.$emit('success');
        return;
      }else if(this.vagaNomeUrl) {
        this.$router.push({ name: 'DetalheVagaCandidato', params: {id: this.vagaNomeUrl }, query: { lang: this.$i18n.locale, a: "1" } });
        return;
      }
      const path = rurl ? rurl : "/";
      this.$router.push({ path: path, query: { lang: this.$i18n.locale } });
      return;
    }
    if (sessionStorage.getItem('linkedin_user')) {
      this.$router.push({ name: 'ThirdPartyRegistry', 
        query: { 
          lang: this.$i18n.locale, 
          vagaURL: (this.vagaNomeUrl||""),
          organica: (this.$route.query.organica||""),
          ccode: this.companyUrl
        } });
    }
  }

  public register() {
    if(this.emitLogin) {
      this.$emit('register',true)
      return;
    }

    const path = "/register";
    this.$router.push({
      path: path,
      query: {
        vagaURL: this.vagaNomeUrl
          ? this.vagaNomeUrl || ""
          : "",
        organica: this.$route.query.organica
          ? this.$route.query.organica || ""
          : "",
        lang: this.$i18n.locale
      }
    });
  }

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
    sessionStorage.removeItem('facebook_user');
  }

  public async registerUser(accessToken?: string) {
    if (accessToken) {
      // this.candidato =  new Candidato();
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("token_type", "Bearer");
      sessionStorage.setItem("profile_type", "candidate");
      await this.userRequest()
        .then(() => {
          /* console.log("ok") */
        })
        .catch(() => {
          this.removeItensFromStorage();
        });
    } else {
      this.removeItensFromStorage();
    }
  }

  public linkedInPopupClosed() {
    this.whereToSend();
  }

  public async loginWithLinkedIn(value: string|LinkedInUser) {
    if (this.linkedinToken) {
      return;
    }

    if (typeof(value) === 'string') {
      this.linkedinToken = value;
      await this.registerUser(this.linkedinToken);
    }
    else {
      const user = value;
      sessionStorage.setItem('linkedin_user', JSON.stringify(user));
    }
  }

  public login() {
    const { username, password } = this;

    if (!username || !password) {
      return;
    }

    this.loginPromise = new Promise(async (resolve) => {
      let token: string|null = null;
      try {
        token = await ClientAuthentication.fetchToken('candidate_login');
      } catch(e) {
        console.log(e)
      }

      this.authRequest({ username, password, token })
      .then((result: any) => {
        this.showDismissibleAlert = false;
        if(!this.emitLogin) {
          this.whereToSend();
        } else {
          this.$emit('success')
        }
      })
      .catch((err: any) => {
        this.showDismissibleAlert = true;
        try {
          this.alertVariant = "danger";
          this.serverMessage = ""; //this.$t("Ocorreu um problema").toString();
          this.serverMessage += " " + err.detail;
        } catch (e) {
          /* console.log(e) */
        }
        
        if(this.emitLogin) {
          this.$emit('fail')
        }
      })
      .finally(resolve);
    });
  }

  public toggleShow() {
    this.showPassword = !this.showPassword;
    this.typePassword = this.showPassword ? "text" : "password";
  }

}
