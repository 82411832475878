import { Vue, Component, Watch } from 'vue-property-decorator';
import { VagaEntity, CandidaturaEntity, CandidatoEntity } from '@/core/models/Entities';
import { JobsInvites } from '@/core/util';
import { Getter, Action } from "vuex-class";
import VagaService from "@/core/Services/Vaga/Vaga";
import CandidaturaService from "@/core/Services/Candidato/Candidatura";
import CandidatoService from "@/core/Services/Candidato/Candidato";

const namespace: string = 'profile';

@Component
export default class VagaDetailMixin extends Vue {
  private vagaService: VagaService = new VagaService();
  private candidaturaService: CandidaturaService = new CandidaturaService();
  private candidatoService: CandidatoService = new CandidatoService();
  private candidatura: CandidaturaEntity|null = null;
  private invite: Boolean = false;
  private applyPromise: Promise<any>|null = null;
  public jobsInvite: JobsInvites;
  public registerRequested: boolean = false;
  public confirmationRequest: boolean = false;
  public noneFound: boolean = false;
  public currentPromise: Promise<any>|null = null;
  public vaga: VagaEntity|null = null;
  public registredEmail: string = "";
  public noSidebar: boolean = false;
  public loading: boolean = false;

  @Getter("isAuthenticated", { namespace }) 
  public _isAuthenticated: any;
  
  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor () {
    super();
    this.jobsInvite = new JobsInvites();
  }

  get companyCode() {
    return this.$route.params.ccode;
  }

  public async created() {
    let data: any = { id:0 };
    if(this.$route.params.urlcode){
      data["urlCode"] = this.$route.params.urlcode;
      data["cCode"] = this.$route.params.ccode;
    }
    const v: VagaEntity|null =  await this.vagaService.publicFetchWithData(data);    
    if (v) {
      this.vaga = v;
      this.jobsInvite.addToList(this.vaga!.nomeUrl, this.vaga!.id);

      this.invite = this.jobsInvite.isOnList(this.vaga!.nomeUrl)
      document.querySelector('meta[property="og:url"]')!.setAttribute("content", ''+location);
      document.querySelector('meta[property="og:title"]')!.setAttribute("content", ''+this.vaga?.posicao);
      document.querySelector('meta[property="og:description"]')!.setAttribute("content", ''+this.vaga?.descricaoSemHtml);
      document.querySelector('meta[property="og:image"]')!.setAttribute("content", ''+this.vaga?.empresa?.logoEmpresa);
    } else {
      this.noneFound = true
    }

    setTimeout( () => {
      if(this.$route.query.a == "1") {
        this.checkProfile()
      }
    },1500)
  }


  public saveModelDiversidade(){
    this.currentPromise = this.candidatoService.save()
      .then(async(data: any) => {
          if(this.vaga) {
            this.$alert.title = this.$t('Success');
            this.$alert.addMsg(this.$t('Profile successfully saved'));
            this.$alert.callBoxSuccess();            
            this.$bvModal.hide('modalDiversidade');
          }
    })
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  get currentLocal() {
    return location.href
  }

  get status() {
    return this.candidatura?.status
  }

  get canAnswer() {
    return this.candidatura?.canAnswer
  }

  get showCompartilhar(): boolean {
    return !!this.vaga && this.vaga.restrita === 'SIM';
  }

  get showEmpresa(): boolean {
    return !!this.vaga && this.vaga.confidencial !== 'SIM';
  }

  get vagaCols(): number {
    return this.showEmpresa ? 9 : 12;
  }

  get applicable() :boolean {
    return this.vaga!.status === 'ATIVO';
  }

  public goDirectToInterview() {
    const routeName: string = this.noSidebar ? 'EntrevistaNoSidebar' : 'Entrevista';
    if(this.candidatura) {
      this.$router.push({
        name: routeName,
        params: { id: this.candidatura!.id!.toString() },
        query: { lang: this.$i18n.locale }
      });
    } else {
      this.apply()
    }
  }

  public goToInterview() {
    const routeName: string = this.noSidebar ? 'EntrevistaNoSidebar' : 'Entrevista';
    // this.$router.push({
    //   name: 'CandidaturaSucesso',
    //   query: { lang: this.$i18n.locale, cid: this.candidatura!.id!.toString()  }
    // });
    this.$router.push({
      name: routeName,
      params: { id: this.candidatura!.id!.toString() },
      query: { lang: this.$i18n.locale }
    }).catch(() => {});
    this.$bvModal.hide('modal-job-step')
  }
  
  public goToExam() {
    // this.$router.push({
    //   name: 'CandidaturaSucesso',
    //   query: { lang: this.$i18n.locale, cid: this.candidatura!.id!.toString()  }
    // });
    this.$router.push({
      name: 'Exams',
      params: { cid: this.candidatura!.id!.toString() },
      query: { lang: this.$i18n.locale }
    });
    this.$bvModal.hide('modal-job-step')
  }

  public sendPerguntasCorte(b: string) {
    if(b) {
      this.candidatarse()
    }
    this.vaga!.isCandidatado = !b;
    this.$bvModal.hide('modalPerguntasCorte')
  }
  get requireFullProfile() : boolean {
    return ((this.vaga!.requireProfile && !this.candidatoService.isProfileCompletedToJob()) 
        ||
        (this.vaga!.requireVideoCurriculum && !this.candidatoService.haveVideoCurriculum()))
  }
  get hiddenCurriculumDone() : boolean {
    return (
              ((this.candidato?.perfil?.formacoes?.length||0) > 0 || this.candidato?.perfil?.temFormacao===false)
           && ((this.candidato?.perfil?.experiencias?.length||0) > 0 || this.candidato?.perfil?.temExperiencia===false)
        )
  }


  insertIntoLocalStorage (key: string, value: unknown, encrypt?: boolean) {
    let valueToString = JSON.stringify(value)
    const verifyWindowType = typeof window !== 'undefined'
  
    if (encrypt) {
      valueToString = btoa(encodeURIComponent(valueToString))
    }
  
    verifyWindowType ? window.localStorage.setItem(key, valueToString) : false
  }

  public canIApply() {
    if(!this.candidato) {
      console.log('not found candidato')
      return false;
    }

    let valid: boolean = true;
    this.candidatoService.entidade = this.candidato;
    //  (this.vaga!.requireVideoCurriculum && !this.candidatoService.haveVideoCurriculum())
    if (this.requireFullProfile)
    {
      valid = false;
      this.$alert.title = this.$t("PROCESS STEPS");
      this.$alert.addMsg(this.$t("1st Step: Complete your profile").toString());
      // this.$alert.addMsg(this.$t("2ª etapa: VÍDEO ENTREVISTA").toString());
    } else if (this.vaga?.mostrarCurriculoOculto) {      
      this.$bvModal.show('modalCurriculoOculto');
      return false;
    }
    
    if (!valid) {
      this.insertIntoLocalStorage('completionMissing', JSON.stringify({
        jobId: this.vaga!.id as unknown as number,
        name: this.vaga!.posicao,
        requireDiversity: false,
        requireProfile: true,
        requireVideoCurriculum: false,
        hasQualifyingQuestions: false,
        hasQuestionaries: false,
        hasRequiredSkills: false,
        hasInterviewQuestions: false
      }))
      this.$alert.callBoxCheck(this.$t("Complete my profile").toString()).then(_ => { _ ? location.href = 'https://career.jobecam.com/curriculum/edit?isInFillingFlow=true' : null;})
    }

    return valid;
  }

  public checkProfile() {
    if(this.canIApply()){
      this.apply()
    }
    this.$bvModal.hide('modal-job-step')
  }

  get hasHOrP()
  {
    return ((this.vaga!.PerguntasCorte && this.vaga!.PerguntasCorte!.length>0) || (this.vaga!.vagaHabilidades && this.vaga!.vagaHabilidades!.length>0));
  }

  public async apply() {
    if(!this.isAuthenticated){
      console.log('Calling apply when not authenticated',this.isAuthenticated)
      this.registerRequested = false;
      this.$bvModal.show('modal-login-vaga')
    } else {
      
      console.log('Calling apply when authenticated')
      let data: any = { id:0 };
      if(this.$route.params.urlcode){
        data["urlCode"] = this.$route.params.urlcode;
        data["cCode"] = this.$route.params.ccode;
      }
      // Make this call to load vaga with detailed data
      this.vaga = await this.vagaService.fetchWithData(data);

      if (this.hasHOrP && this.vaga!.isAllowed) {
        this.$bvModal.show('modalPerguntasCorte');
        if((this.$refs.mpc as any))
          (this.$refs.mpc as any).loadPerguntasCorte();
      } else {
        if(this.applicable && !this.vaga!.isCandidatado && this.vaga!.isAllowed) {
          this.candidatarse()
        } else if(this.vaga?.isCandidatado && this.vaga?.isPendente) {
          this.candidatura = await this.candidaturaService.fetchAll({idvaga: this.vaga.id});
          this.goToInterview();
        }
      }
      this.$bvModal.hide('modal-job-step')
    }
  }

  public candidatarse() {
    this.applyPromise = this.candidaturaService.create({ idvaga: this.vaga!.id, organica: ( this.invite ? "NAO" : "SIM" ) })
      .then((data: CandidaturaEntity) => {
        this.vaga!.isCandidatado = true;

        if (data && data.id) {
          this.candidatura = data

          if (this.candidatura.status === "VIDEOSPENDENTES") {
            this.goToInterview();
          } else if (this.candidatura.status === "REVISAOCURRICULAR") {
            this.$alert.title = this.$t("Application completed!");
            this.$alert.addMsg(this.$t("Your profile is in analysis for the recruiter").toString());
            this.$alert.callBoxCheck(this.$t("Ok").toString());
          } else if (this.candidatura.status === "EXAMESPENDENTES") {
            this.goToExam();
          }
        }
      });
  }
  /**
   * diversitySaved
   */
  public diversitySaved() {
    console.log('dados de diversidade salvos com sucesso')
  }
  
  /**
   * diversityError
   */
  public diversityError() {
    console.log('dados de diversidade não coletados')
  }
  public loginSuccess() {
    this.loading = true;
    this.$bvModal.hide('modal-login-vaga');
    setTimeout(() => {
      this.loading = false;
      this.checkProfile()
    },3000)
    
  }

  public registerFail() {
    console.log('fail')
  }

  public loginFail() {
    console.log('fail')
  }

  public success(data: CandidatoEntity) {
    this.confirmationRequest = true;
    // this.$emit('success',data)
    this.registredEmail = data.email;
  }

  public successConfirmation(data: any) {
    this.$bvModal.hide('modal-login-vaga')
    this.checkProfile()
  }
}