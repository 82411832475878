import Vue from 'vue';
import Router, { Route } from 'vue-router';
import Login from '@/views/Login.vue';
import LoginBR from '@/views/LoginBR.vue';
import RegisterCompleted from '@/views/RegisterCompleted.vue';
import RegisterCompletedSocial from '@/views/RegisterCompletedSocial.vue';
import Terms from '@/views/Terms.vue';
import RootVagas from '@/views/RootVagas.vue';
import RootLoggedArea from '@/views/layouts/RootLoggedArea.vue';
import RootPublicArea from '@/views/layouts/RootPublicArea.vue';
import RootPublicAreaNoSidebar from '@/views/layouts/RootPublicAreaNoSidebar.vue';
import RootPublicEmptyArea from '@/views/layouts/RootPublicEmptyArea.vue';
import PaginaInicial from '@/components/PaginaInicial.vue';
import PerfilView from '@/views/perfil/PerfilView.vue';
import PerfilEdit from '@/views/perfil/PerfilEdit.vue';
import CandidatoRegister from '@/views/CandidatoRegister.vue';
import Vagas from '@/views/vagas/Vagas.vue';
import Candidaturas from '@/views/vagas/Candidaturas.vue';
import CandidaturaSucesso from '@/views/vagas/CandidaturaSucesso.vue';
import VagasSalvas from '@/views/vagas/VagasSalvas.vue';
import DetalheVaga from '@/views/vagas/DetalheVaga.vue';
import VideoCurriculum from '@/views/video-curriculum/VideoCurriculum.vue';
import VideoCurriculumMobile from '@/views/video-curriculum/mobile/VideoCurriculum.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Assessment from '@/components/assessment/Assessment.vue';
import Exams from '@/views/exams/Exams.vue';
import AccountSettings from '@/components/perfil/AccountSettings.vue';
import Entrevista from '@/views/entrevista/Entrevista.vue';
import PublicEntrevista from '@/views/entrevista/PublicEntrevista.vue';
import EntrevistaMobile from '@/views/entrevista/mobile/Entrevista.vue';
import LiveRoomManager from '@/views/live-interview/LiveRoomManager.vue'
import PublicLiveRoomManager from '@/views/live-interview/PublicLiveRoomManager.vue'
import Companies from '@/views/company/Companies.vue';
import FavoriteCompanies from '@/views/company/FavoriteCompanies.vue';
import CompanyProfile from '@/views/company/CompanyProfile.vue';
import ProfilePdf from '@/components/pdf/ProfilePdf.vue';
import PublicProfilePdf from '@/components/pdf/PublicProfilePdf.vue';
import ThirdPartyLoginSuccess from '@/components/ThirdParty/ThirdPartyLoginSuccess.vue';
import ThirdPartyRegistry from '@/components/ThirdParty/ThirdPartyRegistry.vue';
import Mensagens from '@/views/mensagem/Mensagens.vue';
import RedirectLogin from '@/views/RedirectLogin.vue';
import CookiesPrivacyPolicy from '@/views/privacy-policy/CookiesPrivacyPolicy.vue';
import HelpPage from '@/components/help/HelpPage.vue';
import AcademyView from '@/components/academy/AcademyView.vue';
import HiddenTalentInfoPage from '@/views/curriculum/HiddenTalentInfoPage.vue';
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import firebase from "@firebase/app";
import '@firebase/storage'
import "@firebase/analytics";


import RespostaAgenda from '@/views/agenda/RespostaAgenda.vue';
import VagaPublico from '@/views/vagas/DetalheVagaPublico.vue';
import ProcessStartSimple from '@/views/vagas/ProcessStartSimple.vue';
import PerfilPublico from '@/views/perfil/PerfilPublico.vue';
import IphoneError from '@/views/IphoneError.vue';


// import LoginCandidato from '../src/components/LoginCandidato.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import ShareSession from '@/plugins/ShareSession';
import { i18n } from '@/plugins/i18n';

Vue.use(Router);

const isPWA = () => {
  return window && window.matchMedia("(display-mode: standalone)").matches;
}

const callLogout = () => {
  // tslint:disable-next-line: no-console
  /* console.log('logout') */
  const cs: CandidatoService = new CandidatoService();
  cs.logout();
};

const sleep = async (ms: any) => {
  return new Promise((resolve: any) => setTimeout(resolve, ms));
};

const cleanSession = (to: any, from: any, next: any) => {
  sessionStorage.removeItem('token_type');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('profile_type');
  sessionStorage.removeItem('profile');
  next();
}

const isLogged = async (to: any, from: any, next: any) => {
  const c = new ShareSession();
  if (sessionStorage.getItem('access_token') == null) {
    // give it a time to share the session between tabs
    await sleep(500);
  }
  if (sessionStorage.getItem('access_token') == null) {
    next({
      path: '/login',
      query: { lang: to.query.lang, redirectUrl: to.path }
    });
    return;
  }
  next();
};

const checkIfCandidatoHaveMinimumProfile = () => {
  return;
  // const currPath =  this.getRouter().currentRoute.fullPath;
  // if (this.getRouter().currentRoute.query.lang !== this.getI18n().locale) {
  //   this.getRouter().replace({path: this.getRouter().currentRoute.path, query: { lang: this.getI18n().locale }});
  //   location.reload();
  //   return;
  // }
  // const isMyDataPath = (currPath.indexOf('/candidato/meusdados') >= 0);
  // if (this.entidade.perfil && this.entidade.perfil.cadastroConcluido !== 'SIM' && !isMyDataPath) { // PENDENTE
  //   const msg: string = this.whatIsMissing(this.entidade.perfil);

  //   $.alert({
  //     animation: 'scale',
  //     closeIcon: true,
  //     columnClass: 'col-md-6',
  //     content: '' + this.$i18n.t('Favor informar') + ':<br />' + msg,
  //     icon: 'fa fa-lock',
  //     theme: 'material',
  //     title: '' + this.$i18n.t('Falta pouco! Termine seu cadastro para continuar') ,
  //     type: 'red',
  //   });
  //   this.getRouter().push({path: '/candidato/meusdados', query: { lang: this.getI18n().locale }});
  //   return;
  // }
  // // tslint:disable-next-line:no-console
  // /* console.log((this.entidade.perfil!.idioma!.idioma || this.entidade.lingua || 'pt-BR')) */
};
const routerObject: any = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/m/entrevistas/:id',
      name: 'EntrevistaMobile',
      component: EntrevistaMobile,
      meta: { title: 'Interview' },
      beforeEnter: isLogged
    },
    {
      path: '/m/video-curriculum',
      name: 'VideoCurriculumMobile',
      component: VideoCurriculumMobile,
      meta: { title: 'Video curriculum' },
      beforeEnter: isLogged
    },
    {
      path: '/',
      component: RootLoggedArea,
      beforeEnter: isLogged,
      children: [
        {
          path: '',
          name: 'PaginaInicial',
          component: PaginaInicial,
          meta: { title: 'Home' }
        },
        {
          path: '/perfil',
          name: 'PerfilView',
          component: PerfilView,
          meta: { title: 'Profile' }
        },
        {
          path: '/edit',
          name: 'PerfilEdit',
          component: PerfilEdit,
          meta: { title: 'Profile' }
        },
        {
          path: '/vagas',
          component: RootVagas,
          name: 'RootVagas',
          redirect: { name: 'Vagas' },
          children: [
            {
              path: '/',
              name: 'Vagas',
              component: Vagas,
              props: true,
              meta: { title: 'Jobs' }
            },
            {
              path: 'candidaturas',
              name: 'Candidaturas',
              component: Candidaturas,
              meta: { title: 'Applications' }
            },
            {
              path: 'salvas',
              name: 'VagasSalvas',
              component: VagasSalvas,
              meta: { title: 'Saved Jobs' }
            },
            {
              path: ':id',
              name: 'DetalheVagaCandidato',
              component: DetalheVaga,
              meta: { title: 'Jobs' }
            }
          ]
        },
        {
          path: '/video-curriculum',
          name: 'VideoCurriculum',
          component: VideoCurriculum,
          meta: { title: 'Video curriculum' },
          beforeEnter: (to, from, next) => {
            const tabletWidth = 768;

            if (window.innerWidth < tabletWidth) {
              next({
                name: 'VideoCurriculumMobile'
              }).catch(() => {});
            }
            else {
              next();
            }
          }
        },
        {
          path: '/assessment',
          name: 'Assessment',
          component: Assessment,
          meta: { title: 'Cultural Fit' }
        },
        {
          path: '/exams/:cid',
          name: 'Exams',
          component: Exams,
          meta: { title: 'Tests' }
        },
        {
          path: '/settings',
          name: 'AccountSettings',
          component: AccountSettings,
          meta: { title: 'Settings' }
        },
        {
          path: '/ajuda',
          name: 'HelpPage',
          component: HelpPage,
          meta: { title: 'Help' }
        },
        {
          path: '/sucesso',
          name: 'CandidaturaSucesso',
          component: CandidaturaSucesso,
          meta: { title: 'Congratulations' }
        },
        {
          path: '/entrevistas/:id',
          name: 'Entrevista',
          component: Entrevista,
          meta: { title: 'Interview' },
          beforeEnter: (to: any, from: any, next: any) => {
            try {
              const tabletWidth = 768;

              if (window.innerWidth < tabletWidth && !!to?.params?.id) {
                next({
                  name: 'EntrevistaMobile',
                  params: {
                    id: to.params.id
                  }
                })
              }
              else {
                next();
              }
            } catch(e) {
              console.log('router BeforeEnter exception')
            }
          }
        },
        {
          path: '/companies',
          name: 'Companies',
          component: Companies,
          meta: { title: 'Companies' }
        },
        {
          path: '/companies/favorite',
          name: 'FavoriteCompanies',
          component: FavoriteCompanies,
          meta: { title: 'Favorite companies' }
        },
        {
          path: '/companies/:urlcode',
          name: 'CompanyProfile',
          component: CompanyProfile,
          meta: {
            title: 'Company profile',
            metaTags: [
              {
                name: 'description',
                content: 'The home page of our example app.'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              }
            ]
          }
        },
        {
          path: '/mensagens',
          name: 'Mensagens',
          component: Mensagens,
          meta: { title: 'Messages' }
        },
        {
          path: '/academy',
          name: 'AcademyView',
          component: AcademyView,
          meta: { title: 'Academy' }
        }
      ]
    },
    {
      path: '/c',
      component: RootPublicArea,
      children: [
        {
          path: ':urlcode',
          name: 'PerfilPublico',
          component: PerfilPublico,
          meta: { title: 'Candidate profile - Jobecam' }
        }
      ]
    },
    {
      path: '/sala-entrevista',
      component: RootPublicEmptyArea,
      beforeEnter: isLogged,
      children: [
        {
          path: ':sid',
          name: 'SalaEntrevista',
          component: LiveRoomManager,
          meta: { title: 'Candidate profile - Jobecam' }
        }
      ]
    },
    {
      path: '/p/sala-entrevista',
      component: RootPublicEmptyArea,
      children: [
        {
          path: ':sid',
          name: 'PublicSalaEntrevista',
          component: PublicLiveRoomManager,
          meta: { title: 'Anonymous Room' }
        }
      ]
    },
    {
      path: '/e',
      component: RootPublicArea,
      children: [
        {
          path: ':urlcode',
          name: 'CompanyPublicProfile',
          component: CompanyProfile,
          meta: {
            title: 'Company profile',
            metaTags: [
              {
                name: 'description',
                content: 'The home page of our example app.'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              }
            ]
          }
        }
      ]
    },
    {
      path: '/v',
      component: RootPublicArea,
      children: [
        {
          path: ':urlcode',
          name: 'VagaPublico',
          component: VagaPublico,
          meta: { title: 'Job - Jobecam' }
        }
      ]
    },
    {
      path: '/cc',
      component: RootPublicAreaNoSidebar,
      children: [
        {
          path: ':ccode/v/:urlcode',
          name: 'ProcessStartSimple',
          component: ProcessStartSimple,
          meta: { title: 'Job - Jobecam' }
        },
        {
          path: ':ccode/rec/:id',
          name: 'EntrevistaNoSidebar',
          component: Entrevista,
          meta: { title: 'Interview' },
          beforeEnter: (to, from, next) => {
            const tabletWidth = 768;

            if (window.innerWidth < tabletWidth) {
              next({
                name: 'EntrevistaMobile',
                params: {
                  id: to.params.id
                }
              });
            }
            else {
              next();
            }
          }
        }
      ]
    },
    {
      path: '/resposta-entrevista',
      component: RootPublicArea,
      children: [
        {
          path: '',
          name: 'RespostaAgenda',
          component: RespostaAgenda,
          meta: { title: 'Answer for interview scheduling' }
        }
      ]
    },
    {
      path: '/invite',
      component: RootPublicArea,
      children: [
        {
          path: ':urlcode',
          name: 'VagaPublicoInvite',
          component: VagaPublico,
          meta: { title: 'Job Invite - Jobecam' }
        },
      ]
    },
    {
      path: '/p-profile-pdf',
      component: RootPublicEmptyArea,
      children: [
        {
          path: ':urlcode',
          name: 'PublicProfilePdf',
          component: PublicProfilePdf,
          meta: { title: 'Jobecam Curriculum' }
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'Login - Jobecam' }
      // beforeEnter: setLanguage
    },
    {
      path: '/interview-access',
      component: RootPublicEmptyArea,
      children: [
        {
          path: ':tkn',
          name: 'PublicAccessInterview',
          component: LoginBR,
          meta: { title: 'Jobecam Fast Interview' }
        },
        {
          path: ':tkn/int/:cid',
          name: 'FastInterview',
          component: PublicEntrevista,
          meta: { title: 'Jobecam Fast Interview' }
        },
      ]
      // beforeEnter: setLanguage
    },
    {
      path: '/completed',
      name: 'completed',
      component: RegisterCompleted,
      meta: { title: 'Jobecam' }
      // beforeEnter: setLanguage
    },
    {
      path: '/completed-social',
      name: 'CompletedSocial',
      component: RegisterCompletedSocial,
      meta: { title: 'Jobecam' }
      // beforeEnter: setLanguage
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      meta: { title: 'Termos - Jobecam' },
      beforeEnter: () => {
        window.location.href = 'https://jobecam.com/talento-termos-de-uso-e-privacidade/';
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: Login,
      beforeEnter: callLogout,
      meta: { title: 'Logout' }
    },
    {
      path: '/register',
      name: 'CandidatoRegister',
      component: CandidatoRegister,
      meta: { title: 'Register' }
      // beforeEnter: setLanguage
    },
    {
      path: '/forgot',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: { title: 'Forgot password?' }
      // beforeEnter: setLanguage
    },
    {
      path: '/reset',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: { title: 'Reset password' }
      // beforeEnter: setLanguage
    },
    {
      path: '/profile-pdf',
      name: 'ProfilePdf',
      component: ProfilePdf,
      beforeEnter: isLogged
    },
    {
      path: '/hidden-curriculum',
      name: 'HiddenTalentInfoPage',
      component: HiddenTalentInfoPage,
      beforeEnter: isLogged
    },
    {
      path: '/3party',
      name: 'ThirdPartyLoginSuccess',
      component: ThirdPartyLoginSuccess
      // beforeEnter: setLanguage
    },
    {
      path: '/registry-from-social',
      name: 'ThirdPartyRegistry',
      component: ThirdPartyRegistry
      // beforeEnter: setLanguage
    },
    {
      path: '/redirectLogin',
      name: 'RedirectLogin',
      component: RedirectLogin
      // beforeEnter: setLanguage
    },
    {
      path: '/cookies-privacy-policy',
      name: 'cookies-privacy-policy',
      component: CookiesPrivacyPolicy,
      meta: {title: 'Cookie Privacy Policy'},
      // beforeEnter: setLanguage
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: 'about' */ './views/About.vue')
    },
    {
      path: '/iphone-error',
      name: 'iphone-error',
      component: IphoneError,
    }
  ]
};
const router = new Router(routerObject);

router.beforeEach((to: Route, from: Route, next: Function) => {

  if((to.path.indexOf('/edit') > -1 || to.path.indexOf('/entrevista') > -1) && window.jivo_destroy) {
    window.jivo_destroy();
  }

  let lang: string = !to.query.lang ? navigator.language : (to.query.lang as string);

  if (from.path.indexOf('/login') > -1) {
    if (sessionStorage.getItem('profile')) {
      let profile: any = JSON.parse('' + atob(sessionStorage.getItem('profile')+""));
      lang = profile.lang || 'en';
    }
  } else if (!lang) {
    lang = navigator.language;
  }
  // /* console.log(lang) */

  if (['en', 'es', 'pt', 'fr'].indexOf(lang.substring(0, 2)) >= 0) {
    if (lang.substring(0, 2) == 'pt') {
      i18n.locale = 'pt-BR';
    } else {
      i18n.locale = lang.substring(0, 2);
    }
  } else {
    i18n.locale = 'en';
  }
  // /* console.log(i18n.locale) */
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = i18n.t(nearestWithTitle.meta.title)+"";
  }
  const analytics = getAnalytics();

  setCurrentScreen(analytics, to.name||to.path);
  logEvent(analytics, "page_view");
  logEvent(analytics, "screen_view");

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el: any) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key: any) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router;
