import { render, staticRenderFns } from "./Entrevista.vue?vue&type=template&id=4ab4993d&scoped=true"
import script from "./Entrevista.vue?vue&type=script&lang=ts"
export * from "./Entrevista.vue?vue&type=script&lang=ts"
import style0 from "./Entrevista.vue?vue&type=style&index=0&id=4ab4993d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab4993d",
  null
  
)

export default component.exports